<template>
  <div>
    <form-wizard
      color="#29235C"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- accoint details tab -->
      <tab-content
        title="Nouvelle audience"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nom de l'audience"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nom de l'audience"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Âge (min)"
                label-for="age1"
              >
                <b-form-spinbutton
                  id="age1"
                  v-model="age_low"
                  :max="age_up"
                />

              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Âge (max)"
                label-for="age2"
              >
                <b-form-spinbutton
                  id="age2"
                  v-model="age_up"
                  :min="age_low"
                />

              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Genre"
                label-for="genre"
              >
                <b-form-radio-group
                  id="genre"
                  v-model="genre"
                  :options="genreOptions"
                  name="Genre"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Intérêts"
                label-for="interet"
              >
                <b-form-tags
                  v-model="interet"
                  input-id="interet"
                  placeholder="Ajouter un intérêt, un mot clé..."
                  class="mb-2"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTags,
  BFormSpinbutton,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormTags,
    BFormSpinbutton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [{
        id: 1,
        adTitle: '',
        adDescription: '',
        adPricing: '',
        adText: '',
        redirectionUrl: '',
        file: '',
      }],
      interet: [],
      ctaButton: '',
      redirectionUrl: '',
      file: '',
      price: '',
      nextTodoId: 2,
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      adTitle: '',
      last_name: '',
      address: '',
      landMark: '',
      adDescription: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      adText: '',
      required,
      email,
      age_low: 25,
      age_up: 45,
      genre: '',
      genreOptions: [
        { text: 'Homme', value: 'homme' },
        { text: 'Femme', value: 'femme' },
        { text: 'Tous', value: 'tous' },
      ],
      CTAoption: [
        { value: 'postuler_maintenant', text: 'Postuler maintenant' },
        { value: 'reserver', text: 'Réserver' },
        { value: 'nous_contacter', text: 'Nous contacter' },
        { value: 'telecharger', text: 'Télécharger' },
        { value: 'profiter_de_l_offre', text: 'Profiter de l\'offre' },
        { value: 'obtenir_un_devis', text: 'Obtenir un devis' },
        { value: 'obtenir_les_horaires', text: 'Obtenir les horaires' },
        { value: 'obtenir_l_acces', text: 'Obtenir l\'accès' },
        { value: 'demander_l_horaire', text: 'Demander l\'horaire' },
        { value: 'voir_le_menu', text: 'Voir le menu' },
        { value: 'acheter', text: 'Acheter' },
        { value: 's_inscrire', text: 'S\'inscrire' },
        { value: 's_abonner', text: 'S\'abonner' },
        { value: 'voir_d_autres_videos', text: 'Voir d\'autres vidéos' },
        { value: 'envoyer_un_message_whatsapp', text: 'Envoyer un message WhatsApp' },
        { value: 'en_savoir_plus', text: 'En savoir plus' },
      ],
    }
  },
  methods: {
    removeItem(index) {
      this.items.splice(index, 1)
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    formSubmitted() {
      const data = {
        nom: this.name,
        age_min: this.age_low,
        age_max: this.age_up,
        genre: this.genre,
        interets: this.interet,
      }
      axios
        .post('https://app2.oscar-black.com/api/audience', data)
        .then(response => {
          this.$emit('audiencess', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Audience créée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
