<template>
  <b-row>
    <b-col cols="12">
      <section id="apercu">
        <b-card
          no-body
        >
          <b-card-body class="text-center">
            <b-table
              responsive
              striped
              hover
              :items="audiences"
              small
            />
          </b-card-body>
        </b-card>
      </section>
    </b-col>
    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <form-wizard-numberr
        v-if="edit !== null"
        :id="edit"
        @audiencess="audiencess"
      />
    </b-sidebar>
    <b-col cols="12">
      <form-wizard-number
        @audiencess="audiencess"
      />
    </b-col>
  </b-row>
</template>

<script>
import axios from '@axios'
import {
  BTable, BCard, BCardBody, BRow, BCol, BSidebar, VBToggle, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable vue/no-unused-vars */
import FormWizardNumber from './FormWizardNumber.vue'

import FormWizardNumberr from './FormWizardNumberr.vue'

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BSidebar,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    FormWizardNumber,
    FormWizardNumberr,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      audiences: [],
      ready: false,
      edit: null,
    }
  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/audience')
      .then(response => {
        this.audiences = response.data
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
  },
  methods: {
    editEnCours(val) {
      this.edit = val
    },
    audiencess(val) {
      this.audiences = val
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const req = {
            _id: id,
          }
          axios
            .delete('https://app2.oscar-black.com/api/audience', { data: req })
            .then(response => {
              this.designs = response.data
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your ad has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {})
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your ad is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  #sidebar-right {
    width: 550px !important;
  }
</style>
